import { GrandstandButton, GrandstandButtonStyle } from '@/components/Buttons'
import { BaseContainer } from '@/components/containers/BaseContainer'
import { breakpoints } from '@/styles/breakpoints'
import { HeadingLabel, MetaDataLabel, ParagraphLabel, SubheadingLabel } from '@/styles/fonts'
import Image from 'next/image'
import Link from 'next/link'
import ReactModal from 'react-modal'
import styled from 'styled-components'
export const transitionSeconds = 0.2

export const Modal = styled(ReactModal)`
  [style] {
  }
`
export const ModalStyles = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100001;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  transition: opacity ${transitionSeconds}s ease;
`

export const ModalGap = styled(BaseContainer)`
  flex: 1 1 auto;
  padding-top: 0;
  pointer-events: none;
`
export const Wrapper = styled.div`
  position: sticky;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: var(--surface-light);
  border-radius: 8px 8px 0px 0px;
`
export const Container = styled(BaseContainer)`
  --container-padding: var(--container-spacer);
  --container-padding-top: var(--container-spacer);
  position: relative;
  padding: var(--container-padding-top) var(--container-padding) var(--container-padding);
  @media (max-width: 1279px) {
    --container-padding: var(--tablet-container-spacer);
  }
  @media (max-width: 479px) {
    --container-padding: var(--mobile-container-spacer);
  }
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 32px 0;
  & > * {
    max-width: 100%;
    overflow-x: hidden;
  }

  ${breakpoints.up('lg')} {
    grid-template-columns: repeat(auto-fill, minmax(50%, calc(100% - 520px))) minmax(520px, 50%);
    gap: 0 24px;
    grid-template-rows: auto;
  }
`
// Marketing block
export const MarketingBlock = styled.div`
  border-radius: 8px;
  grid-row-start: 2;
  overflow: hidden;
  ${breakpoints.up('lg')} {
    grid-row-start: 1;
    padding-right: 0;
    min-height: 100%;
  }
`
export const MarketingContainer = styled(Container)`
  position: relative;
  display: flex;
  min-height: 100%;
  background-color: var(--canvas);
  color: var(--on-background);
`
export const MarketingImage = styled(Image)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`
export const ImageContainer = styled.div`
  height: 40vh;
`
export const MarketingContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  gap: 24px;
  width: 100%;
  max-width: 640px;
`

export const MarketingLabels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 1 auto;
`
export const MarketingTitle = styled(HeadingLabel)`
  color: var(--on-dark);
  text-align: left;
`
export const LogoWrapper = styled.div`
  display: flex;
  height: 96px;
  width: 100%;
  align-items: flex-end;
  svg {
    height: 32px;
    width: auto;
  }
`
// Modal starter
export const ContentBlock = styled(BaseContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px 0;
  width: 100%;
  min-height: 100%;
  max-width: 520px;
  margin: 0 auto;

  ${breakpoints.up('tablet')} {
    min-height: 100%;
  }
`

export const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TitleRow = styled(ContentRow)``
export const FormRow = styled(ContentRow)`
  justify-self: stretch;
`
export const Actions = styled(ContentRow)``

export const ContentTitle = styled(HeadingLabel)`
  color: var(--on-light);
  text-align: center;
`
export const ContentSubtitle = styled(SubheadingLabel)`
  color: var(--on-light);
  text-align: center;
`
export const TeamsInMarket = styled.div`
  display: grid;
  margin-left: 0;
  grid-template-columns: 1fr 1fr;
  gap: 6px;
  margin-top: 32px;
  ${breakpoints.down('mobile')} {
    grid-template-columns: 1fr;
    gap: 6px;
    max-height: unset;
  }
`
export const TeamInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`

export const TeamDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const TeamName = styled.p`
  color: var(--on-background);
  font-size: 16px;
  line-height: 19px;
`

export const TeamMeta = styled.p`
  font-size: 12px;
  line-height: 17px;
  white-space: nowrap;
`

export const TeamsOutOfMarket = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 48px 0;
`
export const ZipForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;
  gap: 16px;
  color: var(--on-background-alt);
  & > * {
    width: 100%;
  }
`
export const ZipHelper = styled(SubheadingLabel)`
  width: 100%;
  text-align: center;
`

export const ButtonLink = styled(GrandstandButtonStyle)`
  width: 100%;
  text-transform: uppercase;
`

export const Button = styled(GrandstandButton)`
  width: 100%;
  text-transform: uppercase;
`
export const MarketingUL = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 16px;
  padding-bottom: 0;
  width: 100%;
  list-style: '+ ';
`
export const MarketingLI = styled.li`
  padding: 8px 0;
  color: var(--on-background);
`
export const Action = styled.div`
  width: 100%;
`
export const ActionOr = styled(Action)`
  display: flex;
  align-items: center;
  color: var(--on-button-alt);
  &::before,
  &::after {
    display: inline-block;
    content: '';
    pointer-events: none;
    flex: 1 1 auto;
    height: 1px;
    background-color: var(--button-alt);
    padding: 0 16px;
  }
  &::before {
    padding-left: 0;
  }
  &::after {
    padding-right: 0;
  }
`
export const OrText = styled(ParagraphLabel)`
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 16px;
  color: var(--on-background);
`

export const ActionItemSignIn = styled.div`
  text-align: center;
`
export const LinkSignIn = styled(Link)`
  text-decoration: underline;
`
export const CloseRow = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px 16px;
  ${breakpoints.up('lg')} {
    padding-right: 32px;
  }
`
export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`

export const SpinnerStyles = styled.div`
  display: flex;
  justify-content: center;
`

export const InlineLink = styled(Link)`
  text-decoration: underline !important;
  &:hover,
  &:focus {
    color: var(--on-background);
  }
`

export const SignInMeta = styled(MetaDataLabel)`
  color: var(--on-background);
  a,
  ${InlineLink} {
    color: var(--on-background);
    text-decoration: underline;
    &:hover,
    &:focus {
      color: var(--on-background-alt);
    }
  }
`
