import { Logger } from '@grandstand-web/bally-web-shared/src/utils/logger'
import { MetaEntitlementType, UserEntitlement } from '@grandstand/presentation-models'

export type EntitlementMeta = {
  local_network_ids?: string[]
  entitlement_type?: MetaEntitlementType
}

export type TEntitlementReason =
  | ''
  | 'no-restrictions'
  | 'no-network-restrictions'
  | 'free-content'
  | 'mvpd-only'
  | 'in-region-any'
  | 'in-region-mvpd'
  | 'out-of-region'
  | 'paused'
  | 'seasonal_paused'
  | 'no-entitlements'

export type TEntitlementInfoResponse = {
  entitled: boolean
  reason: TEntitlementReason
}

export const getEntitlementsInRegion = (userEntitlements: UserEntitlement[], networks: string[]) => {
  return userEntitlements.filter((entitlement) => {
    const areas = entitlement.local_network_ids
    return areas.some((area) => networks.includes(area))
  })
}

export const getEntitlementsByPermittedType = (userEntitlements: UserEntitlement[], contentType: string) => {
  return userEntitlements.filter((entitlement) => {
    return contentType === 'mvpd' ? entitlement.type === 'mvpd' : true
  })
}

type TPausedUserEntitlement = UserEntitlement & { subscription_status: 'paused' | 'seasonal_paused' }

const separatePausedEntitlements = (
  userEntitlements: UserEntitlement[]
): [UserEntitlement[], TPausedUserEntitlement[]] => {
  const isPausedEntitlement = (entitlement: UserEntitlement): entitlement is TPausedUserEntitlement => {
    return entitlement.subscription_status === 'paused' || entitlement.subscription_status === 'seasonal_paused'
  }

  return userEntitlements.reduce<[UserEntitlement[], TPausedUserEntitlement[]]>(
    (result, entitlement) => {
      if (isPausedEntitlement(entitlement)) {
        result[1].push(entitlement)
      } else {
        result[0].push(entitlement)
      }
      return result
    },
    [[], []]
  )
}

export const getEntitlementInfo = (
  userEntitlements: UserEntitlement[],
  contentMeta?: EntitlementMeta
): TEntitlementInfoResponse => {
  const logger = Logger.of('Entitlements > getEntitlementInfo')

  const logStatus = (status: TEntitlementInfoResponse) => {
    logger.debug(`User${status.entitled ? '' : 'NOT'} entitled reason: ${status.reason}. Derived from this data:`, {
      userEntitlements,
      contentMeta,
    })
  }

  const [entitlements, pausedEntitlements] = separatePausedEntitlements(userEntitlements)

  const _getInfo = (): TEntitlementInfoResponse => {
    if (contentMeta === undefined) {
      return { entitled: true, reason: 'no-restrictions' }
    }
    if (!contentMeta?.local_network_ids) {
      return { entitled: true, reason: 'no-network-restrictions' }
    }
    if (!contentMeta.entitlement_type || contentMeta.entitlement_type === 'free') {
      return { entitled: true, reason: 'free-content' }
    }

    let status: TEntitlementInfoResponse = { entitled: false, reason: 'no-entitlements' }

    const regionEntitlements = getEntitlementsInRegion(entitlements, contentMeta.local_network_ids)
    if (regionEntitlements.length === 0) {
      status = { entitled: false, reason: 'out-of-region' }
    } else {
      const validContentEntitlements = getEntitlementsByPermittedType(regionEntitlements, contentMeta.entitlement_type)
      if (validContentEntitlements.length === 0) {
        status = { entitled: false, reason: 'mvpd-only' }
      }
      if (validContentEntitlements.length > 0) {
        return { entitled: true, reason: `in-region-${contentMeta.entitlement_type}` }
      }
    }

    const validPausedEntitlements = getEntitlementsByPermittedType(
      getEntitlementsInRegion(pausedEntitlements, contentMeta.local_network_ids),
      contentMeta.entitlement_type
    ) as TPausedUserEntitlement[]

    if (validPausedEntitlements.length > 0) {
      return { entitled: false, reason: validPausedEntitlements[0].subscription_status || '' }
    }
    return status
  }

  const status = _getInfo()
  logStatus(status)
  return status
}
