import { UpsellModal } from '@/components/modals/UpsellModal'
import { ModalContext } from '@/contexts/ModalContext'
import { UpsellState, useUpsellLogic } from '@grandstand-web/bally-web-shared/src/hooks/useUpsellLogic'
import { EntitlementMeta } from '@grandstand-web/bally-web-shared/src/newPackages/Entitlements'
import { useRouter } from 'next/router'
import { useContext } from 'react'

export type { EntitlementMeta, UpsellState }
export type UpsellStore = {
  upsell: UpsellState
}
export type UpsellProps = {
  contentUrl: string
  meta: EntitlementMeta | undefined
}
export const useUpsell = ({ contentUrl = '/', meta }: UpsellProps) => {
  const router = useRouter()

  const modalStore = useContext(ModalContext)

  const { upsell } = useUpsellLogic({ contentUrl, meta })

  function openUpsellModal() {
    modalStore.openModal(
      <UpsellModal contentUrl={contentUrl} meta={meta} isSeasonallyPaused={upsell.reason === 'seasonal_paused'} />
    )
  }

  function goToEntitled() {
    if (upsell.isEntitled && contentUrl) {
      router.push(contentUrl)
    } else {
      openUpsellModal()
    }
  }

  return {
    upsell,
    goToEntitled,
    openUpsellModal,
  }
}
