import { MetaDataLabel } from '@/styles/fonts'
import styled from 'styled-components'

const Logo = styled.div`
  display: flex;
  flex-direction: column;
`

type BackgroundCircle = 'dark' | 'light' | 'gray' | null

const LogoImg = styled.img<{ bgCircle: BackgroundCircle }>`
  height: 52px;
  width: 52px;
  ${({ bgCircle }) => {
    const colors = {
      dark: '#333',
      light: 'white',
      gray: '#F3F4f5',
    }
    if (bgCircle !== null) {
      return `
      background: ${colors[bgCircle]};
      padding: 10px;
      border-radius: ${(88 + 10) / 2}px;
    `
    }
  }}
`

const LogoLabel = styled(MetaDataLabel)`
  height: 56px;
  width: 56px;
`

const TeamLogo = ({
  logoId,
  logoImgSrc,
  leagueName,
  bgCircle = null,
}: {
  logoId: string
  logoImgSrc: string
  leagueName?: string
  bgCircle?: BackgroundCircle
}) => {
  return (
    <Logo key={logoId}>
      <LogoImg src={logoImgSrc} bgCircle={bgCircle} />
      {leagueName && (
        <LogoLabel fontSize="sm" tabletFontSize="sm" mobileFontSize="sm">
          {leagueName}
        </LogoLabel>
      )}
    </Logo>
  )
}
export default TeamLogo
