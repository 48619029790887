import TeamLogo from '@/components/checkout/TeamLogo'
import { useColorScheme } from '@/hooks/useColorScheme'
import { getString } from '@grandstand-web/bally-web-shared/src/newPackages/StringMgmt'
import { PartnerTeam } from '@grandstand/presentation-models'
import * as Styles from './styles'

export const UpsellHeader = ({
  availableTeams,
  outOfMarket,
}: {
  availableTeams: PartnerTeam[]
  outOfMarket: boolean
}) => {
  const { isDark } = useColorScheme()
  if (outOfMarket) {
    return (
      <>
        <Styles.ContentRow>
          <Styles.ContentTitle fontSize="3">{"We're sorry"}</Styles.ContentTitle>
          <Styles.ContentSubtitle fontSize="1">Bally Sports+ is not available in this zip code</Styles.ContentSubtitle>
        </Styles.ContentRow>
        <Styles.ContentRow>
          <Styles.TeamsOutOfMarket>No Packages Available</Styles.TeamsOutOfMarket>
        </Styles.ContentRow>
      </>
    )
  }
  return (
    <>
      <Styles.ContentRow>
        <Styles.ContentTitle fontSize="3">
          Your local
          {availableTeams.length > 0 ? <span> teams</span> : <span> team</span>}
        </Styles.ContentTitle>
        <Styles.ContentSubtitle fontSize="1">{getString('upsell.right.subheader')}</Styles.ContentSubtitle>
      </Styles.ContentRow>
      <Styles.ContentRow>
        <Styles.TeamsInMarket>
          {availableTeams.map((team) => {
            const logo = team.light_image || ''
            return (
              <Styles.TeamInfo key={team.name}>
                <TeamLogo bgCircle="gray" key={logo} logoId={logo} logoImgSrc={logo} />
                <Styles.TeamDetails>
                  <Styles.TeamName>{team.name}</Styles.TeamName>
                  <Styles.TeamMeta>
                    {team.league?.toUpperCase()} {team.dtc ? '' : '• TV Provider Only'}
                  </Styles.TeamMeta>
                </Styles.TeamDetails>
              </Styles.TeamInfo>
            )
          })}
        </Styles.TeamsInMarket>
      </Styles.ContentRow>
    </>
  )
}
